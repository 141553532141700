export const historyDataTableConf = {
	"1": [
		{
			label: '直播日期',
			prop: '1',
			type: 'daterange',
			search: true
		},
		{
			label: '主播名称',
			prop: '2',
			type: 'input',
			search: true
		},
		{
			label: '商品照片',
			prop: '3',
		},
		{
			label: '商品名称',
			prop: '4',
			type: 'input',
			search: true
		},
		{
			label: '内部链接',
			prop: '5',
		},
		{
			label: '商家名称',
			prop: '6',
		},
		{
			label: '商品类目',
			prop: '7',
			type: 'select',
			search: true,
			options: []
		},
		{
			label: '店铺名称',
			prop: '8',
		},
		{
			label: '在售价（元）',
			prop: '9',
		},
		{
			label: '近90天最低价（元）',
			prop: '10',
		},
		{
			label: '直播到手价（元）',
			prop: '11',
		},
		{
			label: '赠品',
			prop: '12',
		},
		{
			label: '优惠方式',
			prop: '13',
		},
		{
			label: '优惠描述',
			prop: '14',
		},
		{
			label: '成交笔数',
			prop: '15',
		},
		{
			label: '销售量',
			prop: '16',
		},
		{
			label: '销售总额',
			prop: '17',
		},
		{
			label: '商品链接',
			prop: '18',
		},
	],
	"2": [
		{
			label: '直播日期',
			prop: '1',
			type: 'daterange',
			search: true
		},
		{
			label: '主播名称',
			prop: '2',
			type: 'input',
			search: true
		},
		{
			label: '商品名称',
			prop: '4',
			type: 'input',
			search: true
		},
		{
			label: '零售价（元）',
			prop: '9',
		},
		{
			label: '直播到手价（元）',
			prop: '11',
		},
		{
			label: '优惠方式',
			prop: '13',
		},
		{
			label: '销售量',
			prop: '16',
		},
		{
			label: '销售总额',
			prop: '17',
		},
		{
			label: '商品链接',
			prop: '18',
		},
	]
}