<template>
	<div class="history_data">
		<e-table :tableCols="tableList" :showOperation="true" :dataOrigin="dataOrigin">
			<template #customOperation>
				<el-col :span="6" class="mb10">
					<el-button>导出</el-button>
					<el-button>下载模板</el-button>
					<el-button>外部数据导入</el-button>
				</el-col>
				<el-col :span="24">
					<el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
						<el-radio-button label="1">内部已播数据</el-radio-button>
						<el-radio-button label="2">外部已播数据</el-radio-button>
					</el-radio-group>
				</el-col>
			</template>
		</e-table>
		<e-dialog title="" ref="dialogRef">
		</e-dialog>
	</div>
</template>

<script>
import {historyDataTableConf} from '@data/historyData'
export default {
	name: 'HistoryData',
	data() {
		return {
			historyDataTableConf,
			dataOrigin: {
				data: [
				]
			},
			tabPosition: '1'
		}
	},
	computed: {
		tableList() {
			return historyDataTableConf[this.tabPosition]
		}
	},
	methods: {
		handleClick() {}
	}
}
</script>